import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "components"
    }}>{`Components`}</h1>
    <p>{`There are `}<strong parentName="p">{`6`}</strong>{` components so far in Stele. Each are an abstraction of a
type of ICU category. The main ICU categories are `}<inlineCode parentName="p">{`plural`}</inlineCode>{`, `}<inlineCode parentName="p">{`select`}</inlineCode>{` and `}<inlineCode parentName="p">{`selectordinal`}</inlineCode>{`.
Then there are `}<inlineCode parentName="p">{`formatters`}</inlineCode>{`, which is a way to take a specific data type like `}<inlineCode parentName="p">{`number`}</inlineCode>{` or
`}<inlineCode parentName="p">{`date`}</inlineCode>{` or `}<inlineCode parentName="p">{`time`}</inlineCode>{` and have them behave correctly on regardless of locale.`}</p>
    <p>{`These components are important because `}<inlineCode parentName="p">{`Stele`}</inlineCode>{` is smart enough to understand
these are special and can do compile time optimizations on these components.`}</p>
    <h2 {...{
      "id": "select"
    }}>{`Select`}</h2>
    <h2 {...{
      "id": "percent-decimal"
    }}>{`Percent, Decimal`}</h2>
    <h2 {...{
      "id": "datetime"
    }}>{`DateTime`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      